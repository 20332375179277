export const translationEN = {
    "About Us": "About Us",
    "Our Services": "Our Services",
    "Contact Us": "Contact Us",
    "Book Now": 'Book Now',
    "Discover the ultimate in nail care luxury at CHIC ∙ CHOC Nail Salon. Treat yourself to expert manicures and pedicures in our serene and welcoming atmosphere. Book your appointment today for a rejuvenating experience you won't forget!":" Discover the ultimate in nail care luxury at CHIC ∙ CHOC Nail Salon. Treat yourself to expert manicures and pedicures in our serene and welcoming atmosphere. Book your appointment today for a rejuvenating experience you won't forget!",
    "Step into a Realm of Beauty and Elegance, Where Your Nails Become a Canvas of Creativity.": "Step into a Realm of Beauty and Elegance, Where Your Nails Become a Canvas of Creativity.",
    "Vardananc 16, Yerevan, Armenia": "Vardananc 16, Yerevan, Armenia",
    "Our goal is to provide a luxurious and relaxing experience with excellent nail care and artistry, exceeding client expectations with a range of services including manicures, pedicures and creative nail designs.": "Our goal is to provide a luxurious and relaxing experience with excellent nail care and artistry, exceeding client expectations with a range of services including manicures, pedicures and creative nail designs.",
    "Dare to dream differently with us!": "Dare to dream differently with us!",
    "Got a quirky wish? We've got you covered! Let us sprinkle some magic and make it real, reflecting your awesome personality. And hey, while we're at it, let's pamper those nails with some luxe treatment too!": "Got a quirky wish? We've got you covered! Let us sprinkle some magic and make it real, reflecting your awesome personality. And hey, while we're at it, let's pamper those nails with some luxe treatment too!",
    "Manicure": "Manicure",
    "Pedicure": "Pedicure",
    "Get ready to witness perfection in action! Our awesome team is all about making your nails look and feel amazing. Whether you're after a fancy manicure or just chilling with a pedicure, we promise a super comfy vibe every step of the way.": "Get ready to witness perfection in action! Our awesome team is all about making your nails look and feel amazing. Whether you're after a fancy manicure or just chilling with a pedicure, we promise a super comfy vibe every step of the way.",
    "Go to Services": "Go to Services",
    "KIND WORDS FROM OUR CUSTOMERS": "KIND WORDS FROM OUR CUSTOMERS",
    "Look no further! We've got everything you've been searching for: top-notch service, expert pros, and the perfect chill vibe for maximum comfort. Welcome to your new happy place!": "Look no further! We've got everything you've been searching for: top-notch service, expert pros, and the perfect chill vibe for maximum comfort. Welcome to your new happy place!",
    "our story": "our story",
    "Okay, so picture this: Marieta Vardanyan, or Marik as her crew calls her, is this graphic designer, yeah? But get this – out of the blue, she's like, 'You know what Armenia needs? A killer nail salon!' So, she rolls up her sleeves, leaves the design board behind, and dives headfirst into the nail game. She's all about top-notch service, primo professionals, and making dreams come true. And that's how the magic began!": "Okay, so picture this: Marieta Vardanyan, or Marik as her crew calls her, is this graphic designer, yeah? But get this – out of the blue, she's like, 'You know what Armenia needs? A killer nail salon!' So, she rolls up her sleeves, leaves the design board behind, and dives headfirst into the nail game. She's all about top-notch service, primo professionals, and making dreams come true. And that's how the magic began!",
    "Creating an ideal environment": "Creating an ideal environment",
    "our team": "our team",
    "At our salon, our team is like a family, with each member bringing their unique skills to make your experience exceptional. We have a diverse group of specialists who are dedicated to making you look and feel your best. Our nail technicians are the artists behind those intricate nail designs you love. From classic manicures to elaborate nail art, they have the creativity and expertise to bring your vision to life. With years of experience and ongoing training, they stay updated on the latest trends and techniques, ensuring that your nails are always on point. But our team isn't just about technical skills – it's also about genuine care and connection. Each member is dedicated to making you feel welcomed, valued, and comfortable during your visit. They take the time to listen to your needs, answer your questions, and ensure that every aspect of your experience exceeds your expectations.": "At our salon, our team is like a family, with each member bringing their unique skills to make your experience exceptional. We have a diverse group of specialists who are dedicated to making you look and feel your best. Our nail technicians are the artists behind those intricate nail designs you love. From classic manicures to elaborate nail art, they have the creativity and expertise to bring your vision to life. With years of experience and ongoing training, they stay updated on the latest trends and techniques, ensuring that your nails are always on point. But our team isn't just about technical skills – it's also about genuine care and connection. Each member is dedicated to making you feel welcomed, valued, and comfortable during your visit. They take the time to listen to your needs, answer your questions, and ensure that every aspect of your experience exceeds your expectations.",
    "Visit our calm retreat and leave behind the busy pace of daily life. Our skilled technicians will provide you with exceptional nail care, creating the perfect experience for you.": "Visit our calm retreat and leave behind the busy pace of daily life. Our skilled technicians will provide you with exceptional nail care, creating the perfect experience for you.",
    "Services": "Services",
    "face skin care": "face skin care",
    "AMD": "AMD",
    "hr": "hr",
    "min": "min",
    "Classic Manicure": "Classic Manicure",
    "Gel Nail Polish": "Gel Nail Polish",
    "Japan Style": "Japan Style",
    "Nail Fixing": "Nail Fixing",
    "Nail Refill": "Nail Refill",
    "Refill Correction": "Refill Correction",
    "Classic Pedicure": "Classic Pedicure",
    "Gel Pedicure": "Gel Pedicure",
    "Daily Make-Up": "Daily Make-Up",
    "Wedding Make-Up": "Wedding Make-Up",
    "Eyebrow Shaping": "Eyebrow Shaping",
    "Lamination": "Lamination",
    "Waxing": "Waxing",
    "Simply fill in the necessary information to secure your appointment with us. From preferred service to date and time, your nail care needs are in good hands.": "Simply fill in the necessary information to secure your appointment with us. From preferred service to date and time, your nail care needs are in good hands.",
    "Book an Arrangement": "Book an Arrangement",
    "Name Surname": "Name Surname",
    "Choose Master": "Choose Master",
    "Irina Kostanyan": "Irina Kostanyan",
    "Ani Martoyan": "Ani Martoyan",
    "Phone Number": "Phone Number",
    "Select the Service Type": "Select the Service Type",
    "Book": "Book",
    "The service will cost": "The service will cost",
    "Time": "Time",
    "OOOPS! PAGE NOT FOUND": "OOOPS! PAGE NOT FOUND",
    "Return Homepage": "Return Homepage"
}