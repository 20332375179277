export const headerData = [
    {
        title: "About Us"
    },
    {
        title: "Our Services"
    },
    {
        title: "Contact Us"
    },
]
